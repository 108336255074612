import { Activity } from './ActivityType';
import { useTranslation } from 'react-i18next';
import './ActivityBox.css';

interface Props {
  index: number;
  language: string;
  activity?: Activity;
  decoratorImages?: string[];
}

const ActivityBox = ({ index, activity, language, decoratorImages }: Props) => {
  const { t } = useTranslation();

  const openActivityInNewTabFunction = () => {
    const searchUrlForActivity = `https://www.google.com/search?q=${
      // @ts-expect-error Dynamical attribute access
      activity[language] + t('kids')
    }`;
    window.open(searchUrlForActivity, '_blank', 'noopener, noreferrer');
  };

  return (
    <section
      className="activityCard activityCardMobile"
      id={'activity' + index}
      onClick={openActivityInNewTabFunction}
    >
      <div className="activity-decoration">
        {decoratorImages?.map((image, index) => (
          <img key={'image' + index} src={image} alt="pencil" />
        ))}
      </div>
      <h3 className="activity-header">
        <span>Activity {index}</span>
      </h3>
      <div className="activity-description">
        <p>
          {
            // @ts-expect-error Dynamical attribute access
            activity && activity[language]
          }
        </p>
      </div>
      {activity?.imageLink && (
        <div className="activity-picture">
          <div className="activity-picture-frame">
            <img src={activity.imageLink} alt={activity.alt} />
          </div>
        </div>
      )}
    </section>
  );
};

export default ActivityBox;
