import styles from './PrivacyPolicy.module.css';

export default function PrivacyPolicy() {
  return (
    <div>
      <div className={styles.content}>
        <h1 className={styles.title} id="h.khjp9k91d6il">
          Datenschutzerklärung
        </h1>
        <p className={styles.subtitle}>von Daily Kids Activities</p>
        <p className={styles.dateLine}>(Stand: 05.12.2021)</p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.1afz9q5dlzdr">
          § 1 Information über die Erhebung personenbezogener Daten
        </h2>
        <p className={styles.paragraph}>
          (1) Im Folgenden informieren wir Sie über die Erhebung
          personenbezogener Daten bei Nutzung unserer Website. Personenbezogene
          Daten sind alle Daten, die auf Sie persönlich beziehbar sind, zum
          Beispiel Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
        </p>
        <p className={styles.paragraph}>
          (2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
          (“DSGVO”) für die Datenverarbeitung auf dieser Website ist
        </p>

        <address className={styles.ownerInfo}>
          <p className={styles.addressLine}>Daily Kids Activities</p>
          <p className={styles.addressLine}>Vladislav Lisitsyn</p>
          <p className={styles.addressLine}>Engadiner Straße 4</p>
          <p className={styles.addressLine}>81475, München</p>
          <p className={styles.addressLine}>Deutschland</p>
          <p className={styles.addressLine}>
            E-Mail:{' '}
            <a href="mailto:info@daily-kids-activities.com">
              info@daily-kids-activities.com
            </a>
          </p>
        </address>

        <p className={styles.paragraph}>
          (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
          Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
          E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
          gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang
          anfallenden Daten löschen wir, nachdem die Speicherung für die
          Bearbeitung Ihrer Anfrage nicht mehr erforderlich ist, oder schränken
          die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten
          bestehen.
        </p>
        <p className={styles.paragraph}>
          (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte
          Dienstleister zurückgreifen oder Ihre Daten für gewerbliche Zwecke
          nutzen möchten, werden wir Sie untenstehend im Detail über die
          jeweiligen Vorgänge informieren. Dabei nennen wir auch die
          festgelegten Kriterien der Speicherdauer.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.443evusv4stq">
          § 2 Ihre Rechte
        </h2>
        <p className={styles.paragraph}>
          (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
          betreffenden personenbezogenen Daten:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Recht auf unentgeltliche Auskunft (Art. 15 DSGVO) insbesondere über
            Zweck, Empfänger und Dauer der Verarbeitung
          </li>
          <li className={styles.listItem}>
            Recht auf Berichtigung und Löschung (Art. 16 f. DSGVO)
          </li>
          <li className={styles.listItem}>
            Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
          </li>
          <li className={styles.listItem}>
            Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)
          </li>
          <li className={styles.listItem}>
            Recht auf Datenübertragbarkeit (Art. 20 DSGVO) d.h. das Recht,
            Daten, die wir auf Grundlage einer Einwilligung des Nutzers oder in
            Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
            einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern der Nutzer die direkte Übertragung der
            Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </li>
        </ul>
        <p className={styles.paragraph}>
          (2) Sie haben zudem das Recht, sich bei einer
          Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
          personenbezogenen Daten durch uns zu beschweren. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist das Bayerische
          Landesamt für Datenschutzaufsicht, dessen Kontaktdaten folgendem Link
          entnommen werden können:
        </p>
        <p className={styles.paragraph}>
          <a
            className={styles.textNormal}
            href="https://www.bfdi.bund.de/SharedDocs/Adressen/DE/LfD/Bayern.html"
          >
            https://www.bfdi.bund.de/SharedDocs/Adressen/DE/LfD/Bayern.html
          </a>
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.4j5yt4regdyh">
          § 3 Recht auf Widerspruch oder Widerruf gegen die Verarbeitung Ihrer
          Daten
        </h2>
        <p className={styles.paragraph}>
          (1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
          haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf
          beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
          Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben. Die
          Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt
          vom Widerruf unberührt.
        </p>
        <p className={styles.paragraph}>
          (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
          Interessenabwägung stützen, insbesondere auf Art. 6 Abs. 1 S. 1 lit. f
          DSGVO, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies
          ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung
          eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der
          nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei
          Ausübung eines solchen Widerrufs bitten wir um Darlegung der Gründe,
          weshalb wir Ihre personenbezogenen Daten nicht wie von uns
          durchgeführt verarbeiten sollten. Wir prüfen die Sachlage und werden
          entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen
          unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir
          die Verarbeitung fortführen.
        </p>
        <p className={styles.paragraph}>
          (3) Selbstverständlich können Sie der Verarbeitung Ihrer
          personenbezogenen Daten für Zwecke der Werbung und Datenanalyse
          jederzeit widersprechen.
        </p>
        <p className={styles.paragraph}>
          (4) Widerruf bzw. Widerspruch sind an die unter § 1 Abs. 2 genannten
          Kontaktangaben zu richten.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.ro433rph64ex">
          § 4 Erhebung personenbezogener Daten bei Besuch unserer Website
        </h2>
        <p className={styles.paragraph}>
          (1) Bei der bloß informatorischen Nutzung der Website erheben wir nur
          die personenbezogenen Daten, die Ihr Browser automatisch an unseren
          Server übermittelt (sog. Server-Log-Dateien). Wenn Sie unsere Website
          betrachten möchten, erheben wir die folgenden Daten, die für uns
          technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
          die Stabilität und Sicherheit zu gewährleisten:
        </p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            IP-Adresse und Hostname des zugreifenden Rechners
          </li>
          <li className={styles.listItem}>Datum und Uhrzeit der Anfrage</li>
          <li className={styles.listItem}>
            Zeitzonendifferenz zur Greenwich Mean Time (GMT)
          </li>
          <li className={styles.listItem}>
            Inhalt der Anforderung (konkrete Seite)
          </li>
          <li className={styles.listItem}>Zugriffsstatus/HTTP-Statuscode</li>
          <li className={styles.listItem}>Jeweils übertragene Datenmenge</li>
          <li className={styles.listItem}>
            Website, von der die Anforderung kommt
          </li>
          <li className={styles.listItem}>Browser</li>
          <li className={styles.listItem}>
            Betriebssystem und dessen Oberfläche
          </li>
          <li className={styles.listItem}>
            Sprache und Version der Browsersoftware
          </li>
        </ul>
        <p className={styles.paragraph}>
          Rechtsgrundlage für diese Datenerhebung ist Art. 6 Abs. 1 S. 1 lit. f
          DSGVO.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.tknwvtk5cmx0">
          § 5 Einbindung von YouTube-Videos
        </h2>
        <p className={styles.paragraph}>
          (1) Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die
          auf{' '}
          <a className={styles.textNormal} href="https://www.youtube.com">
            http://www.youtube.com
          </a>{' '}
          gespeichert und von unserer Website aus direkt abspielbar sind.
        </p>
        <p className={styles.paragraph}>
          Die Videos sind alle im „erweiterten Datenschutzmodus“ eingebunden,
          d.h. dass keine Daten über Sie als Nutzer an YouTube übertragen
          werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos
          abspielen, werden die in Absatz 2 genannten Daten übertragen. Auf
          diese Datenübertragung haben wir keinen Einfluss.
        </p>
        <p className={styles.paragraph}>
          (2) Mit Ihrer Einwilligung erhält YouTube die Information, dass Sie
          die entsprechende Unterseite unserer Website aufgerufen haben. Zudem
          werden die unter § 4 dieser Erklärung genannten Daten übermittelt.
          Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto
          bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto
          besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt
          Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei
          YouTube nicht wünschen, müssen Sie sich vor Aktivierung des
          „Play“-Buttons ausloggen. YouTube speichert Ihre Daten als
          Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
          und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
          Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer)
          zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
          sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
          informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
          dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube
          richten müssen.
        </p>
        <p className={styles.paragraph}>
          (3) Wir nutzen das Einbinden von YouTube-Videos auf unserer Website
          zur ansprechenden Darstellung unserer Online-Angebote nur mit Ihrer
          Einwilligung. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. a DSGVO.
        </p>
        <p className={styles.paragraph}>
          (4) Informationen des Drittanbieters: Google Ireland Ltd., Gordon
          House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
          Weitere Informationen zu Zweck, Umfang und Dauer der Datenerhebung und
          ihrer Verarbeitung durch YouTube erhalten Sie in der
          Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu
          Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer
          Privatsphäre:{' '}
          <a
            className={styles.textNormal}
            href="https://www.google.de/intl/de/policies/privacy"
          >
            https://www.google.de/intl/de/policies/privacy
          </a>
          . Damit willigen Nutzer zugleich gem. Art. 49 Abs. 1 lit. a DSGVO ein,
          dass ihre Daten in den USA verarbeitet werden können. Wir weisen
          darauf hin, dass das Datenschutzniveau in den USA nicht den
          Datenschutzanforderungen der Europäischen Union genügt. Daten des
          Nutzers können dort möglicherweise auch ohne
          Rechtsbehelfsmöglichkeiten verarbeitet werden. Ein Zugriff durch
          US-Behörden kann dabei nicht ausgeschlossen werden.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.jelr7fezxcin">
          § 6 Nutzung von Plausible Analytics
        </h2>
        <p className={styles.paragraph}>
          (1) Wir nutzen den Webanalysedienst „Plausible Analytics“, um unser
          Angebot fortlaufend technisch und inhaltlich zu optimieren.
        </p>
        <p className={styles.paragraph}>
          Plausible erfasst zu diesem Zweck unter anderem die folgenden
          Informationen: Datum und Uhrzeit Ihres Besuches, Titel und URL der
          besuchten Seiten, eingehende Links, das Land, in dem Sie sich
          befinden, und den User Agent Ihres Browsers. Plausible verwendet und
          speichert keine Cookies auf Ihrem Endgerät. Sämtliche
          personenbezogenen Daten werden ausschließlich vollständig anonymisiert
          in Form eines sogenannten Hashes gespeichert. Ein Hash ist eine
          Verschlüsselung von Daten, die nicht umkehrbar ist, also nicht
          „entschlüsselt“ werden kann. Auf diese Weise können wir Ihren Besuch
          analysieren, ohne personenbezogene Daten in einer Form zu speichern,
          die von uns, Plausible oder sonstige Dritte lesbar ist.
        </p>
        <p className={styles.paragraph}>
          (2) Informationen des Drittanbieters: Plausible Insights OÜ, Västriku
          tn 2, 50403, Tartu, Estonia.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.86ij5cbv9qyq">
          § 7 Auftragsverarbeitung durch Firebase Hosting
        </h2>
        <p className={styles.paragraph}>
          Unsere Website wird mithilfe des Dienstes Firebase Hosting der Google
          Ireland Ltd. gehostet. Die Google Ireland Ltd. speichert in unserem
          Auftrag alle bei der Nutzung unserer Website erhobenen
          personenbezogenen Daten als Auftragsdatenverarbeiter im Sinne des Art.
          28 DSGVO. Die Verarbeitung dieser Daten durch die Google Ireland Ltd.
          findet, laut deren Angaben, ausschließlich in der Bundesrepublik
          Deutschland, in Mitgliedstaaten der Europäischen Union oder
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          statt.
        </p>
        <p className={styles.paragraph}>
          Die Übermittlung personenbezogener Daten durch uns an die Google
          Ireland Ltd. erfordert jedes Mal, dass die Voraussetzungen mindestens
          einer in dieser Datenschutzerklärung genannten Rechtsgrundlagen
          erfüllt sind.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.rj8bfwobz23o">
          § 8 Sicherheit Ihrer Daten
        </h2>
        <p className={styles.paragraph}>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
          wird. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt
          übertragen wird, erkennen Sie an der geschlossenen Darstellung des
          Schlüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste
          Ihres Browsers.
        </p>
        <p className={styles.paragraph}>
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
          oder vorsätzliche Manipulationen, teilweisen oder vollständigen
          Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>

        <h2 className={styles.sectionTitle} id="h.y2ad87d6y2bf">
          § 9 Änderungen dieser Datenschutzerklärung
        </h2>
        <p className={styles.paragraph}>
          Diese Datenschutzerklärung ist auf dem Stand vom 05.12.2021. Durch die
          Weiterentwicklung unserer Website und die Implementierung neuer
          Technologien zur Verbesserung unseres Services oder aufgrund
          geänderter gesetzlicher bzw. behördlicher Vorgaben können Änderungen
          dieser Datenschutzerklärung erfolgen. Daher empfehlen wir Ihnen, sich
          diese Datenschutzerklärung von Zeit zu Zeit erneut durchzulesen. Die
          jeweils aktuelle Fassung kann jederzeit auf dieser Website von Ihnen
          abgerufen und ausgedruckt werden.
        </p>
        <p className={styles.paragraph}>&nbsp;</p>
      </div>
    </div>
  );
}
