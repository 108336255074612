import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';

class VenueClass {
  constructor(
    readonly name: string,
    readonly description: string,
    readonly language: string,
    readonly categories: string[],
    readonly location: {
      city: string;
      country: string;
    },
    readonly website: string,
    readonly createdAt: Timestamp,
    readonly id?: string,
    readonly minAge?: number,
    readonly previewImageUrl?: string
  ) {}

  static cloneWithoutId(venue: Venue): Venue {
    const clonedVenue = { ...venue };
    delete clonedVenue['id'];
    return clonedVenue;
  }
}

export type Venue = VenueClass;

export const venueConverter = {
  toFirestore(venue: Venue): Venue {
    return VenueClass.cloneWithoutId(venue);
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Venue>,
    options: SnapshotOptions
  ): Venue {
    const data = snapshot.data(options)!;
    return new VenueClass(
      data.name,
      data.description,
      data.language,
      data.categories,
      data.location,
      data.website,
      data.createdAt,
      data.id,
      data.minAge,
      data.previewImageUrl
    );
  },
};
