import { Await, useLoaderData } from 'react-router-dom';
import './BlogPost.css';
import { BlogPost as BlogPostType } from './BlogPostType';
import { Suspense } from 'react';
import Spinner from '../Spinner';

export default function BlogPost() {
  const data = useLoaderData() as { blogPost: Promise<BlogPostType> };

  return (
    <div className="blog-post-container">
      <Suspense fallback={<Spinner />}>
        <Await
          resolve={data.blogPost}
          errorElement={<p>Error while loading!</p>}
        >
          {blogPost => (
            <article className="blog-post">
              <section className="blog-post-header">
                <h1>{blogPost?.title}</h1>
                <h2>{blogPost?.subtitle}</h2>
              </section>

              <div className="blog-post-body">
                <figure>
                  <img
                    src={blogPost?.featuredImageUrl}
                    alt={blogPost?.featuredImageCaption}
                  />
                </figure>

                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: blogPost?.body ?? '' }}
                />
              </div>
            </article>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
