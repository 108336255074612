import styles from './TermsOfService.module.css';

export default function TermsOfService() {
  return (
    <div className="termsOfService">
      <div className={styles.content}>
        <h1 className={styles.title} id="h.l87etcdsnpkf">
          Nutzungsbedingungen
        </h1>
        <p className={styles.subtitle}>von Daily Kids Activities</p>
        <p className={styles.dateLine}>(Stand: 05.12.2021)</p>

        <h2 className={styles.sectionTitle} id="h.hj3e6srkm62n">
          § 1 Haftungsausschluss
        </h2>
        <p className={styles.paragraph}>
          Soweit gesetzlich zulässig, sind wir nicht verantwortlich, wenn die
          auf dieser Website zur Verfügung gestellten Informationen nicht
          korrekt, vollständig oder aktuell sind. Die Inhalte unserer Website
          dienen nur der allgemeinen Information und sollten nicht als alleinige
          Grundlage für Entscheidungen herangezogen werden, ohne dass Sie sich
          selbst ausführlich informiert haben. Das Vertrauen in die Inhalte
          unserer Website erfolgt auf eigenes Risiko.
        </p>

        <h2 className={styles.sectionTitle} id="h.8wz7dfkzqo2g">
          § 2 Verfügbarkeit des Dienstes
        </h2>
        <p className={styles.paragraph}>
          Wir streben eine umfassende Verfügbarkeit der angebotenen
          Informationen an. Für Ausfälle unserer Website schließen wir jedoch
          jede Verantwortlichkeit aus. Ein Anspruch auf eine jederzeitige
          Verfügbarkeit kann nicht gewährt werden. Für internet- oder
          netzbedingte Ausfallzeiten oder sonstige Probleme, die nicht in
          unserem Einflussbereich liegen, wie beispielsweise höhere Gewalt oder
          Handlungen Dritter, sind wir nicht verantwortlich.
        </p>

        <h2 className={styles.sectionTitle} id="h.fn4bzjmt695h">
          § 3 Änderungen des Dienstes
        </h2>
        <p className={styles.paragraph}>
          Wir behalten uns das Recht vor, Teile unserer Website oder die Website
          als Ganzes jederzeit zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen. Wir sind nicht
          verpflichtet, Informationen auf unserer Website zu aktualisieren.
        </p>
      </div>
    </div>
  );
}
