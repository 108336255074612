import {
  collection,
  getDocs,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { db } from '../FirebaseConfig';
import { Venue, venueConverter } from './VenueType';

const venuesRef = collection(db, 'venues').withConverter(venueConverter);

export const getVenues = async ({ params }: LoaderFunctionArgs) => {
  const { lang } = params;

  const venuesQuery = query<Venue, Venue>(
    venuesRef,
    where('language', '==', lang || 'en')
  );

  const venuesSnapshot: QuerySnapshot<Venue> = await getDocs(venuesQuery);

  return venuesSnapshot.docs.map(doc => doc.data());
};
