import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, useLoaderData } from 'react-router-dom';
import Spinner from '../Spinner';
import styles from './VenuesOverview.module.css';
import { Venue } from './VenueType';

export default function VenuesOverview() {
  const { t } = useTranslation();
  const data = useLoaderData() as { venues: Promise<Venue[]> };

  return (
    <div className={styles.venuesContainer}>
      <h1>{t('venues')}</h1>
      <Suspense fallback={<Spinner />}>
        <Await resolve={data.venues} errorElement={<p>Error while loading!</p>}>
          {venues => (
            <>
              {venues.map((venue: Venue, index: number) => (
                <div key={index} className={styles.venueContainer}>
                  {index > 0 && <div className={styles.venuePreviewDivider} />}

                  <a
                    href={venue.website}
                    className={styles.venuePreview}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <section className={styles.venuePreviewContent}>
                      <div className={styles.venuePreviewDescription}>
                        <h2>{venue.name}</h2>
                        <p>{venue.description}</p>
                        <div className={styles.venueCategories}>
                          {venue.categories.map((category, idx) => (
                            <span key={idx} className={styles.categoryBadge}>
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>

                      {venue.previewImageUrl && (
                        <figure>
                          <img
                            src={venue.previewImageUrl}
                            alt={venue.name}
                            className={styles.venueImage}
                          />
                        </figure>
                      )}
                    </section>
                  </a>
                </div>
              ))}
            </>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
