import { Timestamp } from 'firebase/firestore';

const milliSecsPerDay = 24 * 60 * 60 * 1000;

export function getYesterdayAsTimestamp() {
  return Timestamp.fromDate(new Date(Date.now() - milliSecsPerDay));
}

export function createTimestampOfTodayAt0AM() {
  return Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0, 0)));
}
