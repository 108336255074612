import { Link } from 'react-router-dom';
import styles from './NavButton.module.css';
import blogIcon from './pictures/nav/bx-news.svg';
import venuesIcon from './pictures/nav/bx-map-pin.svg';
import i18n from './i18n';

interface NavButtonType {
  icon: string;
  alt: string;
  route: string;
  title: string;
}

const BUTTON_TYPES = {
  blog: {
    icon: blogIcon,
    alt: 'News paper',
    route: 'blog',
    title: 'Blog',
  },
  venues: {
    icon: venuesIcon,
    alt: 'Map pin',
    route: 'venues',
    title: i18n.t('venues'),
  },
} as const;

const COLORS = {
  light: { color: '#fa6325', backgroundColor: '#fa6325' },
  dark: { color: '#1e574f', backgroundColor: '#3a9b92' },
} as const;

type ButtonTypeKey = keyof typeof BUTTON_TYPES;
type ButtonColorKey = keyof typeof COLORS;

interface NavButtonProps {
  type: ButtonTypeKey;
  color: ButtonColorKey;
}

export default function NavButton({ type, color }: NavButtonProps) {
  const buttonType: NavButtonType = BUTTON_TYPES[type];
  const buttonColor = COLORS[color];

  return (
    <Link
      className={styles.navIcon}
      style={{ color: buttonColor.color }}
      to={buttonType.route}
    >
      <img
        className={styles.navIconImage}
        style={{ background: buttonColor.backgroundColor }}
        src={buttonType.icon}
        alt={buttonType.alt}
      />
      <span>{buttonType.title}</span>
    </Link>
  );
}
