import { useTranslation } from 'react-i18next';
import { Await, useLoaderData } from 'react-router-dom';
import ActivityBox from './ActivityBox';
import styles from './DailyActivities.module.css';
import balloon from './pictures/balloon.svg';
import familyActivity from './pictures/family-activities.svg';
import pencil1 from './pictures/pencil-1.svg';
import pencil2 from './pictures/pencil-2.svg';
import pencil from './pictures/pencil.svg';
import { Suspense } from 'react';
import { Activity } from './ActivityType';
import NavButton from './NavButton';

export default function DailyActivities() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const data = useLoaderData() as { activities: Promise<Activity[]> };

  function parallax(
    mouseX: number,
    mouseY: number,
    distortion: number,
    element: string
  ) {
    const elem = document.getElementById(element);
    if (elem) {
      elem.style.marginLeft = mouseX * distortion + 'rem';
      elem.style.marginTop = mouseY * distortion + 'rem';
    }
  }

  document.addEventListener('DOMContentLoaded', () => {
    document.onmousemove = function (e) {
      const x = e.clientX;
      const y = e.clientY;
      parallax(x, y, 0.001, 'decoration-pencil-1');
      parallax(x, y, -0.0005, 'decoration-pencil-2');
      parallax(x, y, 0.0015, 'decoration-balloon-1');
      parallax(x, y, 0.0005, 'decoration-balloon-2');

      // @ts-expect-error It can't be null
      document.getElementById('header').style.paddingTop = y * 0.001 + 'rem';
      // @ts-expect-error It can't be null
      document.getElementById('header').style.marginBottom = -y * 0.001 + 'rem';
    };
  });

  return (
    <>
      <header
        id="header"
        className={`${styles.header} ${styles.headerDesktop}`}
      >
        <nav className={styles.navigation}>
          <NavButton type={'blog'} color={'light'} />
          <NavButton type={'venues'} color={'light'} />
        </nav>
        <img
          className={styles.headerImageDesktop}
          src={familyActivity}
          alt="a family sitting on the floor playing games"
          width="402px"
          height="217px"
        />
        <h1 className={`${styles.title} ${styles.titleDesktop}`}>
          Kids Activities
        </h1>
        <h2 className={`${styles.description}`}>{t('homePageDescription')}</h2>
      </header>
      <div className={styles.decorationLeft}>
        <img
          src={pencil1}
          alt="pencil drawing"
          id="decoration-pencil-1"
          className={styles.decorationLeftImage}
          width="110px"
          height="110px"
        />
        <img
          src={pencil}
          alt="pencil drawing"
          id="decoration-pencil-2"
          width="149px"
          height="149px"
        />
      </div>

      <div className={styles.decorationRight}>
        <img
          src={balloon}
          alt="balloon drawing"
          id="decoration-balloon-1"
          width="114px"
          height="240px"
        />
      </div>

      <div className={`${styles.decorationRightTop} xs-hidden`}>
        <div className={styles.decorationBalloon2} />
      </div>

      <main className={` ${styles.activities} ${styles.activitiesMobile}`}>
        <Suspense
          fallback={
            <>
              <ActivityBox index={1} language="" />
              <ActivityBox index={2} language="" decoratorImages={[pencil1]} />
              <ActivityBox
                index={3}
                language=""
                decoratorImages={[pencil, pencil2]}
              />
            </>
          }
        >
          <Await
            resolve={data.activities}
            errorElement={<p>Error while loading!</p>}
          >
            {activities => (
              <>
                <ActivityBox
                  index={1}
                  activity={
                    (activities.length > 0 && activities[0]) || undefined
                  }
                  language={language}
                />
                <ActivityBox
                  index={2}
                  activity={
                    (activities.length > 1 && activities[1]) || undefined
                  }
                  language={language}
                  decoratorImages={[pencil1]}
                />
                <ActivityBox
                  index={3}
                  activity={
                    (activities.length > 2 && activities[2]) || undefined
                  }
                  language={language}
                  decoratorImages={[pencil, pencil2]}
                />
              </>
            )}
          </Await>
        </Suspense>
      </main>
    </>
  );
}
