import { ChangeEvent, useState } from 'react';

import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './App.css';
import { removeRobotsTag } from './Error';
import { db } from './FirebaseConfig';
import './i18n';
import { supportedLanguages } from './i18n';

export default function App() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { pathname } = useLocation();
  const [feedback, setFeedback] = useState('');
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  updateCanonicalUrl(pathname);

  const langSubstring = language ? language.substring(0, 2) : 'en';
  const lang =
    supportedLanguages.indexOf(langSubstring) > -1 ? langSubstring : 'en';

  document.documentElement.setAttribute('lang', lang);

  removeRobotsTag();

  function showFeedbackBubble() {
    if (!isFeedbackOpen) {
      const feedbackModal = document.getElementById('feedback-modal');

      if (feedbackModal) {
        feedbackModal.style.right = -250 + 'px';
        feedbackModal.style.bottom = -100 + 'px';
      }
    }
  }

  function hideFeedbackBubble() {
    if (!isFeedbackOpen) {
      const feedbackModal = document.getElementById('feedback-modal');
      if (feedbackModal) {
        feedbackModal.style.right = -300 + 'px';
        feedbackModal.style.bottom = -110 + 'px';
      }
    }
  }

  function toggleFeedbackBubble() {
    const feedbackModal = document.getElementById('feedback-modal');
    const footerHeight = document.getElementById('footer')?.offsetHeight ?? 0;
    if (!feedbackModal) {
      return;
    }
    if (!isFeedbackOpen) {
      feedbackModal.style.right = -10 + 'px';
      feedbackModal.style.bottom = +footerHeight - 10 + 'px';

      setIsFeedbackOpen(true);
    } else {
      feedbackModal.style.right = -300 + 'px';
      feedbackModal.style.bottom = -110 + 'px';

      setIsFeedbackOpen(false);

      showFeedbackBubble();
    }
  }

  function saveFeedback() {
    const hiddenInput: HTMLInputElement =
      (document.getElementById('feedback-check') as HTMLInputElement) ??
      new HTMLInputElement();
    if (hiddenInput && !hiddenInput.value && feedback !== '') {
      addDoc(collection(db, 'feedbacks'), {
        feedback: feedback,
        createdAt: Timestamp.fromDate(new Date()),
      }).then(() => {
        console.info('Success!!!!!!!!!!!!!');
      });
      alert(t('thanksForFeedback'));
    }
    setFeedback('');
    toggleFeedbackBubble();
  }

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setFeedback(event.currentTarget.value);
  }

  return (
    <>
      <Outlet />
      <div className="feedback-modal" id="feedback-modal">
        <div id="close-modal" onClick={toggleFeedbackBubble}>
          &#10005;
        </div>
        <form>
          <h3>{t('letUsKnowWhatYouThink')}</h3>
          <label className="feedback-text" htmlFor="feedback-text">
            <textarea
              id="feedback-text"
              value={feedback}
              onChange={handleChange}
            />
          </label>
          <input type="hidden" id="feedback-check" />
          <label className="feedback-send" htmlFor="feedback-send">
            <input
              id="feedback-send"
              type="button"
              onClick={saveFeedback}
              value={t('send')}
            />
          </label>
        </form>
      </div>
      <footer id="footer">
        <p>
          © Kids Activities &nbsp; &nbsp;{' '}
          <Link to={`${lang}/terms`}>{t('terms')}</Link> &nbsp; &nbsp;
          <Link to={`${lang}/privacy`}>{t('privacy')}</Link> &nbsp; &nbsp;
          <Link to={`${lang}/imprint`}>{t('imprint')}</Link> &nbsp; &nbsp;
          <Link to={`${lang}/blog`}>{t('blog')}</Link> &nbsp; &nbsp;
          <Link to={`${lang}/venues`}>{t('venues')}</Link>
          <span
            className="feedback"
            onMouseOver={showFeedbackBubble}
            onMouseOut={hideFeedbackBubble}
            onClick={toggleFeedbackBubble}
          >
            &#128172; <span className="xs-hidden">{t('sendUsFeedBack')}</span>
          </span>
        </p>
      </footer>
    </>
  );
}

function updateCanonicalUrl(pathname: string) {
  const canonicalUrl = `https://daily-kids-activities.com${pathname}`;
  let linkElement = document.querySelector("link[rel='canonical']");

  if (!linkElement) {
    linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'canonical');
    document.head.appendChild(linkElement);
  }

  linkElement.setAttribute('href', canonicalUrl);
}
